import React from 'react';

const EmailInput = ({
    name, placeholder, label, className, required,
}) => (
    <div className={`form-field form-text ${className}`}>
        <label htmlFor={name} className="text-label">{label}</label>
        <input
            id={name}
            name={name}
            type="email"
            placeholder={placeholder}
            className="form-text__input"
            required={required}
        />
    </div>
);

EmailInput.defaultProps = {
    name: '',
    placeholder: '',
    label: '',
    suffix: '',
    className: ''
};

export default EmailInput;
