import React from 'react';

const TextInput = ({
    name, placeholder, label, className, onChange, hideLabel, required
}) => (
    <div className={`form-field form-text ${className}`}>
        <label htmlFor={name} className={`text-label${(hideLabel) ? ' hidden' : ''}`}>{label}</label>
        <input id={name} name={name} type="text" placeholder={placeholder} className="form-text__input" required={required} onChange={onChange} />
    </div>
);

TextInput.defaultProps = {
    name: '',
    placeholder: '',
    label: '',
    suffix: '',
    className: '',
    hideLabel: false
};

export default TextInput;
